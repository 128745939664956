import './Products.scss';
import package1 from '../../assets/products/package-1.png';
import package2 from '../../assets/products/package-2.png';
import package3 from '../../assets/products/package-3.png';
import {Box, Heading, Text} from "@chakra-ui/react";

import EnglishPackages from '../../lang/en/products.json';
import IrishPackages from '../../lang/ie/products.json';
import {FormattedMessage} from "react-intl";

function Products(props) {
    const images = [];
    images['package1'] = package1;
    images['package2'] = package2;
    images['package3'] = package3;

    const whichPackage = () => {
        switch (true) {
            case props.totalDays >= 1 && props.totalDays <= 29:
                return 'package1';
            case props.totalDays >= 30 && props.totalDays <= 44:
                return 'package2';
            case props.totalDays > 44:
                return 'package3';
            default:
                return 'package3';
        }
    };

    const getImage = (product) => {
        return images[product];
    };

    let packages = EnglishPackages;

    const currentUrl = window.location.toString();

    if (currentUrl.search('/ie') !== -1) {
        packages = IrishPackages;
    }

    let product = packages[whichPackage()];

    return (
        <Box className="packages-container" textAlign={'center'}>
            <Heading as="h3" size="xl">{product.heading}</Heading>

            <img className={'product-image'} src={getImage(whichPackage())} alt=""/>

            <Heading as="h4" size="md" mb={3}>{product.subheading}</Heading>

            <Text>{product.description}</Text>

            <Box my={6}>
                <Heading as="h3" size="xl">{product.price}</Heading>
                <Text fontSize="14">
                    <FormattedMessage id="product.bottlePrice"/>
                </Text>
            </Box>

            <Text>{product.totalPrice}</Text>
        </Box>
    )
}

export default Products;
